import {makeAutoObservable} from 'mobx';

class GoodsList {
    show = false;
    goodsList = [
        {
            id: 1,
            name: '商品名称1',
            currentPrice: 2300,
            originPrice: 3000,
            count: 10,
            img: '',
            specificationMap: new Map([
                ['重量', '3g'],
                ['颜色', '白色']
            ])
        },
        {
            id: 2,
            name: '商品名称2',
            currentPrice: 2300,
            originPrice: 3000,
            count: 10,
            img: '',
            specificationMap: new Map([
                ['重量', '4g'],
                ['颜色', '红色']
            ])
        }
    ];


    constructor() {
        makeAutoObservable(this)
    }
}

const goodsList = new GoodsList();

export default goodsList;
