import {makeAutoObservable} from 'mobx';

class Shop3 {
    categoryKeyword = '';
    shopName = '';
    shopUsername = '';
    shopPassword = '';
    ifAddSuffix = false;
    shopNameError = '';
    shopUsernameError = '';
    shopPasswordError = '';
        
    constructor() {
        makeAutoObservable(this)
    }
}

const shop3 = new Shop3();

export default shop3;
