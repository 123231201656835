import {
    http,
    Header,
    ContentType,
    qs,
    GetFormData,
    StatusCode,
    Global,
    Method
} from './api-util';


export const afterSaleInfo = ({orderId}) => {
    return http({
        method: Method.GET,
        url: Global.BASE_URL + '/after-sale',
        params: {
            orderId
        }
    }).then(res => res.data);
}

export const addAfterSale = ({orderId,reason}) => {
    return http({
        method: Method.POST,
        url: Global.BASE_URL + '/after-sale',
        data: {
            orderId,
            reason
        }
    })
}