import * as ActionType from 'utils/ActionType';

const type = {
  INIT: 'INIT',
  SET_SHOP_ID: 'SET_SHOP_ID',
  SET_USERNAME: 'SET_USERNAME',
  SET_CURRENT_MENU_INDEX: 'SET_CURRENT_MENU_INDEX',
  SET_RECOMMEND_LIST: 'SET_RECOMMEND_LIST',
  SET_RECOMMEND_PAGE_SIZE: 'SET_RECOMMEND_PAGE_SIZE',
  SET_RECOMMEND_PAGE_NUM: 'SET_RECOMMEND_PAGE_NUM',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT'
};

ActionType.handleType({ type, namespace: 'user/' });

export default type;
