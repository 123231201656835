import { combineReducers } from 'redux';
import type from './action-type';

const reducer = {};

reducer.hotWordList = (state = [], action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_HOT_WORD_LIST:
      return payload;
    default:
      return state;
  }
};

export default combineReducers({ ...reducer });
