import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { store, persistor } from "store/store";
import mobxStore from "./store-mobx/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { Provider as MobxProvider } from "mobx-react";

// 默认语言为 en-US，如果你需要设置其他语言，推荐在入口文件全局设置 locale
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

const root = document.getElementById("root");
ReactDOM.render(
  <MobxProvider {...mobxStore}>
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </>
  </MobxProvider>,
  root
);
serviceWorker.unregister();
