import {makeAutoObservable} from 'mobx';
import CompanyGender from 'enum/CompanyGender';

class Company {
    gender = CompanyGender.Male;
    name = '';
    mobilephone = '';
    email = '';


    constructor() {
        makeAutoObservable(this)
    }
}

export default new Company();
