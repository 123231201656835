import {
  http,
  Header,
  ContentType,
  qs,
  GetFormData,
  StatusCode,
  Global,
  Method
} from './api-util';

export const askQuestion = async ({ question }) => {
  const { data } = await http({
    method: Method.GET,
    url: Global.BASE_URL + '/intelligent/question/info',
    params: {
      question
    }
  });

  const { answer } = data;

  return {
    answer
  };
};
