import * as Bean from './bean';
import {makeAutoObservable} from "mobx";
import * as Api from "api/api";

class Shop2 {
    shopTypeList = [];
    categoryList = [];
    childCategoryList = [];
    shopType = null;
    mainCategory = null;
    selectedCategoryId = 0;
    selectedChildCategoryMap = new Map();
    hasLicense = null;
    licenseMap = new Map();

    get childCategoryListAllActive() {
        if (this.childCategoryList.length === 0) {
            return false;
        }

        return this.childCategoryList.every(item => {
            return item.active;
        });
    }

    set childCategoryListAllActive(value) {
        if (value) {
            this.childCategoryList.forEach(item => {
                item.active = true;
            });
        } else {
            this.childCategoryList.forEach(item => {
                item.active = false;
            });
        }
    }

    get shopTypeId() {
        if (!this.shopType) {
            return 0;
        } else {
            return this.shopType.id;
        }
    }

    get mainCategoryId() {
        if (!this.mainCategory) {
            return 0;
        } else {
            return this.mainCategory.id;
        }
    }

    get selectedCategoryListData() {
        let list = [];

        for (const [parentId, categoryList] of this.selectedChildCategoryMap) {
            list.push({
                levelOneId: parentId,
                levelTwoOptIds: categoryList
                    .filter(item => item.active)
                    .map(item => item.optId)
            });
        }

        return JSON.stringify(list);
    }

    get selectedCategoryList() {
        let list = [];

        for (const [parentId, categoryList] of this.selectedChildCategoryMap) {
            list.push(
                ...categoryList
                    .filter(item => item.active)
                    .map(item => ({...item, parentId}))
            );
        }

        return list;
    }


    get licenseListData() {
        let list = [];

        for (let [categoryId, license] of this.licenseMap) {
            const {picture, endDate, isInfinite} = license;
            list.push({
                categoryId,
                qualificationPic: picture,
                maturitydate: isInfinite ? -1 : endDate.valueOf()
            });
        }

        return list;
    }

    async fetchShopTypeList() {
        try {
            const {list} = await Api.shopTypeList();
            this.shopTypeList = list.map(item => {
                const {id, shopType: value} = item;

                return {
                    id,
                    value
                };
            });
        } catch (error) {
        }
    }

    async fetchCategoryList() {
        try {
            const {list} = await Api.pddMainGoodsCategoryList();

            this.categoryList = list.map(item => {
                const {id, optId, name: value, level, icon, iconActive} = item;

                return {
                    id,
                    optId,
                    value
                };
            });
        } catch (error) {
        }
    }

    async fetchChildCategoryList({parentId, parentOptId, parentValue}) {
        try {
            const {list} = await Api.pddChildGoodsCategoryList({parentId, parentOptId});

            if (this.selectedChildCategoryMap.has(parentId)) {
                this.childCategoryList = this.selectedChildCategoryMap.get(parentId);
            } else {
                this.childCategoryList = list.map(item => {
                    const {id, optId, name: value} = item;

                    return {
                        id,
                        optId,
                        value,
                        active: false,
                        parentValue
                    };
                });
            }
        } catch (error) {
        }
    }

    async fetchData() {
        this.fetchShopTypeList();
        this.fetchCategoryList();
    }

    selectChildCategory({parentId, childList}) {
        this.selectedChildCategoryMap.set(parentId, childList);

        //更新 license map
        if (childList.filter(item => item.active).length === 0) {
            this.licenseMap.delete(parentId);
        } else if (!this.licenseMap.has(parentId)) {
            this.licenseMap.set(
                parentId,
                new Bean.License(childList[0].parentValue)
            );
        }
    }

    deleteChildCategory({parentId, id}) {
        this.selectedChildCategoryMap.get(parentId).forEach(item => {
            if (item.id === id) {
                item.active = false;
                return;
            }
        });

        //删除对应的license
        if (
            this.selectedChildCategoryMap
                .get(parentId)
                .every(item => item.active === false)
        ) {
            this.licenseMap.delete(parentId);
        }
    }


    constructor() {
        makeAutoObservable(this)
    }
}

let shop2 = new Shop2();

export default shop2;
