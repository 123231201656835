export default function GetFormData(param) {
  let formData = new FormData();

  for (let key in param) {
    if (param[key] !== undefined && param[key] !== null) {
      if (Array.isArray(param[key])) {
        Array.from(param[key]).forEach(item => {
          formData.append(key, item);
        });
      } else {
        formData.append(key, param[key]);
      }
    }
  }

  return formData;
}
