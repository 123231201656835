import {makeAutoObservable} from 'mobx';
import company from "./company/company-store";
import company2 from './company2/company2-store';
import shop from './shop/shop-store';
import shop2 from './shop2/shop2-store';
import shop3 from './shop3/shop3-store';
import MerchantStatus from 'enum/MerchantStatus';
import Router from 'router/router';
import * as Api from "api/api";

let history = require('history').createBrowserHistory();

class Merchant {
    company = company;
    company2 = company2;
    shop = shop;
    shop2 = shop2;
    shop3 = shop3;

    step = [
        {
            label: '首页',
            show: false,
            values: [0]
        },
        {
            label: '入驻须知',
            show: true,
            values: [1]
        },
        {
            label: '公司信息认证',
            show: true,
            values: [2, 3]
        },
        {
            label: '公司信息认证2',
            show: false
        },
        {
            label: '店铺信息认证',
            show: true,
            values: [4, 5, 6]
        },
        {
            label: '店铺信息认证2',
            show: false
        },
        {
            label: '店铺信息认证3',
            show: false
        },
        {
            label: '等待审核',
            show: true,
            values: [7]
        },
        {
            label: '完成',
            show: false,
            values: [8]
        }
    ];
    currentStepIndex = 0;
    categoryList = [];
    merchantStatus = null;


    get menuStep() {
        return this.step.filter(item => item.show);
    }


    get notAudit() {
        return this.merchantStatus === MerchantStatus.not_audit;
    }

    query() {
        const {status} = this.merchantStatus;

        if (status === MerchantStatus.auditing) {
            this.currentStepIndex = 7;
        } else if (
            status === MerchantStatus.approve ||
            status === MerchantStatus.not_approve
        ) {
            this.currentStepIndex = 8;
        } else if (status === MerchantStatus.not_audit) {
            this.toNextStep();
        }

        if (history.location !== `${Router.basename}${Router.Merchant.path}/home`) {
            history.push({
                pathname: Router.basename + Router.Merchant.path
            });
        }
    }

    /**
     * 获取类别列表
     */
    async fetchCategoryList() {
        try {
            const {list} = await Api.pddGoodsCategoryList({parentId: 0});

            if (list.length < 8) {
                for (let i = 0; i < 8 - list.length; i++) {
                    list.push({
                        id: 0 - (i + 1),
                        name: '',
                        icon: '',
                        iconActive: ''
                    });
                }
            }

            this.categoryList = list;
        } catch (error) {
        }
    }

    toNextStep() {
        this.currentStepIndex++;
    }

    toPrevStep() {
        this.currentStepIndex--;
    }

    resetStep() {
        this.currentStepIndex = 2;
    }

    async fetchMerchantStatus() {
        try {
            let {info} = await Api.merchantAuditStatus();

            this.merchantStatus = info;
        } catch (error) {
        }
    }

    async submit() {
        try {
            const {company, company2, shop, shop2, shop3} = this;

            /**
             * 先上传图片
             */
            let licenseListData = await Promise.all(
                shop2.licenseListData.map(async item => {
                    let {qualificationPic: file} = item;

                    let qualificationPic = '';
                    if (file) {
                        const {filePath} = await Api.uploadImg({file});
                        qualificationPic = filePath;
                    }
                    return {
                        ...item,
                        qualificationPic
                    };
                })
            );

            await Api.addMerchant({
                name: company.name,
                telPhone: company.mobilephone,
                email: company.email,
                gender: company.gender,
                companyName: company2.name,
                legalPersonName: company2.person,
                idCard: company2.idNumber,
                legalPersonIdPicFileList: company2.idPictureList,
                companyLocationProvince: company2.provinceId,
                companyLocationCity: company2.cityId,
                companyLocationRegion: company2.districtId,
                companyDetailLocation: company2.detailedAddress,
                establishTime: company2.createDateData,
                businessEndTime: company2.termEndData,
                businessStartTime: company2.termStartData,
                registeredCapital: company2.registeredCapital,
                businessScope: company2.scope,
                businessLicensePicListFile: company2.licenseData,
                companyPhone: company2.phone,
                companyEmergencyContact: company2.emergencyPerson,
                companyEmergencyContactPhone: company2.emergencyPhone,
                organizationCode: company2.code,
                companyWebsiteAddress: shop.officialWebsite,
                salesIneLatestYear: shop.saleroom,
                similarElectronicWebsiteExperience: shop.hasExperience,
                hasExperience: shop.shopWebsite,
                onlineShopOperatorsNumber: shop.personNumber,
                goodsNumberForSaleOnline: shop.goodsNumber,
                averageUnitPrice: shop.predictAveragePrice,
                warehouse: shop.stock,
                warehouseAddress: shop.warehouseAddress,
                commonLogisticsCompany: shop.generalLogistics,
                erpType: shop.erpType,
                agentOperatingCompanyName: shop.agentOperatingCompanyName,
                traderSandStorage: shop.selectOurWarehouse,
                liquorMallLogistics: shop.selectOurLogistics,
                openDistributionQualification: shop.openSaleQualification,
                verticalStanding: shop.receivedInvite,
                expectedShopType: shop2.shopTypeId,
                mainCategory: shop2.mainCategoryId,
                detailCategoryIds: shop2.selectedCategoryListData,
                hasCategoryIndustryQualification: shop2.hasLicense,
                categoryIndustryQualification: JSON.stringify(licenseListData),
                categoryDescribeKeyword: shop3.categoryKeyword,
                expectShopName: shop3.shopName,
                shopLoginName: shop3.shopUsername,
                shopLoginPassword: shop3.shopPassword,
                shopSuffix: shop3.ifAddSuffix
            });
            this.toNextStep();
        } catch (error) {
            console.log(error);
        }
    }


    constructor() {
        makeAutoObservable(this)
    }
}

let merchant = new Merchant();

export default merchant;
