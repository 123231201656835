import {makeAutoObservable} from "mobx";
import ImgEnum from 'enum/ImgEnum';
import * as Api from "api/api";

class HomeStore {
    secKillGoodsList = [];
    selfFloor = null;
    goodsFloorList = [];
    guessList = [];
    lookAroundList = [];
    newsList = [];


    async fetchSecKillGoodsList() {
        const {list} = await Api.homeSecKillGoodsList({
            pageSize: 12,
            pageNum: 1
        });
        this.secKillGoodsList = list;
    }


    async fetchSelfFloor(hasSelf) {
        let {selfFloor} = await Api.selfFloor({
            pageNum: 1,
            pageSize: 16
        });

        if (!hasSelf) {
            let {list} = await Api.recommendList({
                pageSize: 12,
                pageNum: 1
            });

            list = list.splice(0, 12);

            selfFloor = {...selfFloor, hotGoodsList: list};
        }
        this.selfFloor = selfFloor
    }


    async fetchGuessList() {
        let {list} = await Api.recommendList({pageSize: 10, pageNum: 1});

        list = list.splice(0, 10);
        this.guessList = list;
    }


    fetchLookAroundList({pageSize, pageNum}) {

        Api.recommendList({pageSize, pageNum}).then(({list}) => {

            let i_5 = Math.floor(list.length / 5);
            list = list.slice(0, i_5 * 5);


            if (pageNum === 1) {
                this.lookAroundList = list;
            } else if (pageNum > 1) {
                this.lookAroundList = [...this.lookAroundList, ...list]
            }
        })

    }


    async fetchNewsList() {
        const {list, totalNum} = await Api.homeNewsList({
            pageSize: 15,
            pageNum: 1
        });
        this.newsList = list;
    }


/*    async fetchBigBanner() {
        const {list} = await Api.homeBigBanner({pageSize: 5, pageNum: 1});
        this.bigBanner = list;
    }*/


/*    async fetchCategoryNav() {
        let {list} = await Api.imgList({type: ImgEnum.SPECIAL});

        const {
            hasSelfCategory
        } = await Api.pddGoodsCategoryListByParentId({
            parentId: 0,
            parentOptId: 0
        });

        list = await Promise.all(list.map(async imgInfo => {
            const {id, imgUrl: img, title: name, optKeyId} = imgInfo;

            const opt = await Api.pddOpt(optKeyId);

            return {
                id,
                img,
                name,
                opt: {
                    ...opt,
                    hasSelf: hasSelfCategory
                }
            };
        }));


        console.log('list:%o', [...list])

        if (list.length < 7) {
            for (let i = 0; i < 7 - list.length; i++) {
                list.push({
                    id: 0 - (i + 1),
                    name: '',
                    img: ''
                });
            }
        }

        this.categoryNav = list;
    }*/


    modifyGoodsFloorList({id, value}) {
        let goodsFloorList = [...this.goodsFloorList]

        for (let i = 0; i < goodsFloorList.length; i++) {
            if (goodsFloorList[i].id === id) {
                goodsFloorList[i] = {...goodsFloorList[i], ...value};
            }
        }

        this.goodsFloorList = goodsFloorList;
    }


    constructor() {
        makeAutoObservable(this)
    }
}

const store = new HomeStore();
export default store;