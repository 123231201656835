import {
    http,
    Header,
    ContentType,
    qs,
    GetFormData,
    StatusCode,
    Global,
    Method
} from './api-util';

export const recommendList = async ({pageSize, pageNum}) => {
    return http({
        method: Method.POST,
        url: Global.BASE_URL + '/api/index/look/around',
        data: {
            page: pageNum,
            size: pageSize
        }
    })
        .then(res => res.data)
        .then(data => {
            // const {lookAroundGoodsList: list} = data;
            const list = data?.lookAroundGoodsList || [];

            const {totalNum} = data;

            return {
                list,
                totalNum
            };
        })
        .catch(err => {
            if (err === '拼多多') {
                return Promise.resolve({
                    list: [],
                    totalNum: 0
                })
            } else if (err === "网络错误") {
                return recommendList(({pageSize, pageNum}));
            } else {
                return {
                    list: [],
                    totalNum: 0
                }
            }
        })
};
