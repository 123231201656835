import * as ActionType from 'utils/ActionType';

const type = {
  INIT: 'INIT',
  SET_PAGE_SIZE: 'SET_PAGE_SIZE',
  SET_PAGE_NUM: 'SET_PAGE_NUM',
  SET_LIST: 'SET_LIST',
  SET_ORDER: 'SET_ORDER',
  SET_SORT_FIELD: 'SET_SORT_FIELD'
};

ActionType.handleType({ type, namespace: 'user/goods-collect/' });

export default type;
