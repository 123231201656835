import {
  http,
  Header,
  ContentType,
  qs,
  GetFormData,
  StatusCode,
  Global,
  Method
} from './api-util';

export const VerifySrc = Global.BASE_URL + '/api/common/imgeverify';
