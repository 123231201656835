import { combineReducers } from 'redux';

import type from './action-type';

const reducer = {};

// INIT:'INIT',
//     SET_LIST:'SET_LIST',
reducer.list = (state = [], action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.INIT:
      return [];
    case type.SET_LIST:
      return payload;
    default:
      return state;
  }
};
//     SET_PAGE_SIZE:'SET_PAGE_SIZE',
//     SET_PAGE_NUM:'SET_PAGE_NUM',
export const page = {
  size: 20,
  num: 1
};
reducer.page = (state = page, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.INIT:
      return page;
    case type.SET_PAGE_SIZE:
      return { ...state, size: payload };
    case type.SET_PAGE_NUM:
      return { ...state, num: payload };
    default:
      return state;
  }
};

//     SET_LIMIT_TIME_LIST:'SET_LIMIT_TIME_LIST',
reducer.limitTimeList = (state = [], action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.INIT:
      return [];
    case type.SET_LIMIT_TIME_LIST:
      return payload;
    default:
      return state;
  }
};
//     SET_RECOMMEND_LIST:'SET_RECOMMEND_LIST',
reducer.recommendList = (state = [], action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.INIT:
      return [];
    case type.SET_RECOMMEND_LIST:
      return payload;
    default:
      return state;
  }
};

reducer.keyword = (state = '', action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.INIT:
      return '';
    case type.SET_KEYWORD:
      return payload;
    default:
      return state;
  }
};

export default combineReducers({ ...reducer });
