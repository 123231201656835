import {
  http,
  Header,
  ContentType,
  qs,
  GetFormData,
  StatusCode,
  Global,
  Method
} from './api-util';

export const hotWordList = async ({ pageNum, pageSize }) => {
  const { data } = await http({
    method: Method.POST,
    url: Global.BASE_URL + '/hot/search/list',
    data: {
      page: pageNum,
      size: pageSize,
      categoryId: 0
    }
  });

  const list = data.msg.objectList.map(item => {
    const { id, word: hotWord, categoryId, categoryName } = item;

    return {
      id,
      hotWord
    };
  });

  return {
    list
  };
};
