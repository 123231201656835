import {Global, http, Method,} from "./api-util";

export async function fetchPGoodsCategoryList({page, size, parentId, name, level}) {
    return http({
        url: Global.BASE_URL + '/api/p-goods-category',
        method: Method.GET,
        params: {
            page, size, parentId, name, level
        }
    }).then(res => res.data)
}

export async function fetchPGoodsCategoryTree({id}) {
    return http({
        url: Global.BASE_URL + `/api/p-goods-category/tree/${id}`,
        method: Method.GET,
    }).then(res => res.data)
}

export async function fetchPGoodsCategoryTreeList({pid}) {
    return http({
        url: Global.BASE_URL + '/api/p-goods-category/tree/list',
        method: Method.GET,
        params: {
            pid
        }
    }).then(res => res.data)
}