import { combineReducers } from 'redux';

import type from './action-type';

const reducer = {};

reducer.map = (state = null, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.INIT:
      return null;
    case type.SET_MAP:
      return payload;
    default:
      return state;
  }
};

const page = {
  size: 15,
  num: 1
};

reducer.page = (state = page, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.INIT:
      return page;
    case type.SET_PAGE_SIZE:
      return { ...state, size: payload };
    case type.SET_PAGE_NUM:
      return { ...state, num: payload };
    default:
      return state;
  }
};

export default combineReducers({ ...reducer });
