import * as Api from "api/api";
import {makeAutoObservable} from "mobx";

class Seo {
    title = '';
    keyword = '';
    description = '';

    async fetchSeo() {
        try {
            const {info} = await Api.seoInfo();
            const {title, keyword, description} = info;

            this.title = title;
            this.keyword = keyword;
            this.description = description;
        } catch (error) {
        }
    }


    constructor() {
        makeAutoObservable(this)
    }
}

export default new Seo();
