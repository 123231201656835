/*
 * @Author: your name
 * @Date: 2020-11-04 15:44:46
 * @LastEditTime: 2021-05-08 11:37:12
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /website/src/api/goods.js
 */
import {
    http,
    Header,
    ContentType,
    qs,
    GetFormData,
    StatusCode,
    Global,
    Method
} from './api-util';

// 商品详情
export const goodsInfo = async ({goodsId, type}) => {
    const {data} = await http({
        method: Method.GET,
        url: Global.BASE_URL + '/api/goods/detail',
        params: {
            goodsId,
            type
        }
    });

    return {
        skuList: data.productList,
        goods: {...data.goods, id: data.goods.id},
        hasCollect: data.userHasCollect,
        collectId: data.collectId,
        goodsAttributeList: data.goodsPropList,
        specificationMapList: data.goodsSpecificationList,
        goodsPromotionUrl: data.goods_promotion_url
    };
};
