import {makeAutoObservable} from "mobx";
import * as Api from "api/api";

const provinceList = [{id: 0, name: '省份'}];
const cityList = [{id: 0, name: '城市'}];
const districtList = [{id: 0, name: '国家'}];

class Company2 {
    name = '';
    code = '';
    person = '';
    idNumber = '';
    idPictureList = [];
    provinceId = 0;
    cityId = 0;
    districtId = 0;
    detailedAddress = '';
    createDate = null;
    termStart = null;
    termEnd = null;
    termEndIsInfinite = false;
    registeredCapital = '';
    scope = '';
    license = null;
    licenseUrl = '';
    phone = '';
    emergencyPerson = '';
    emergencyPhone = '';
    provinceList = provinceList;
    cityList = cityList;
    districtList = districtList;


    get createDateData() {
        if (!this.createDate) {
            return undefined;
        } else {
            return this.createDate.valueOf();
        }
    }


    get termStartData() {
        if (!this.termStart) {
            return undefined;
        } else {
            return this.termStart.valueOf();
        }
    }


    get termEndData() {
        if (this.termEndIsInfinite) {
            return -1;
        } else if (!this.termEnd) {
            return undefined;
        } else {
            return this.termEnd.valueOf();
        }
    }


    get licenseData() {
        if (this.license) {
            return [this.license];
        } else {
            return undefined;
        }
    }


    fetchProvinceList() {
        Api.provinceList().then(list => {
            this.provinceList = list.map(item => {
                const {id, name} = item;

                return {id, name};
            });
            this.cityList = cityList;
            this.districtList = districtList;
            this.cityId = 0;
            this.districtId = 0;
        });
    }

    fetchCityList({parentId}) {
        Api.areaList({parentId}).then(list => {
            this.cityList = list.map(item => {
                const {id, name} = item;
                return {id, name};
            });
            this.districtList = districtList;
            this.districtId = 0;
        });
    }

    fetchDistrictList({parentId}) {
        Api.areaList({parentId}).then(list => {
            this.districtList = list.map(item => {
                const {id, name} = item;
                return {id, name};
            });
        });
    }


    constructor() {
        makeAutoObservable(this)
    }
}

let company2 = new Company2();

export default company2;
