import {
  http,
  Header,
  ContentType,
  qs,
  GetFormData,
  StatusCode,
  Global,
  Method
} from './api-util';

export const homeNewsList = async ({ pageSize, pageNum }) => {
  const { data } = await http({
    method: Method.POST,
    url: Global.BASE_URL + '/news/notice/index/notice/list',
    data: {
      page: pageNum,
      size: pageSize
    }
  });

  const list = data.msg.objectList;

  const totalNum = data.msg.totalNum;

  return { list, totalNum };
};

/**
 * 获取资讯列表
 * @param {*} param0
 */
export const newsList = async ({
  pageNum,
  pageSize,
  categoryId,
  title,
  content
}) => {
  const { data } = await http({
    method: Method.POST,
    url: Global.BASE_URL + '/news/notice/list',
    data: {
      page: pageNum,
      size: pageSize,
      type: categoryId,
      title,
      content
    }
  });

  const list = data.msg.objectList.map(item => {
    const {
      id,
      title,
      author,
      watchTimes,
      content,
      publishTime,
      thumbnail,
      categoryId,
      categoryName,
      netEase
    } = item;

    return {
      id,
      title,
      author,
      watchTimes,
      content,
      publishTime,
      thumbnail,
      categoryId,
      categoryName,
      netEase
    };
  });

  const { totalNum } = data.msg;

  return {
    list,
    totalNum
  };
};

export const newsInfo = async ({ id }) => {
  const { data } = await http({
    method: Method.GET,
    url: Global.BASE_URL + '/news/notice/info',
    params: {
      id
    }
  });

  const {
    title,
    content,
    publishTime,
    updateTime,
    source,
    author,
    watchTimes,
    thumbnail,
    categoryId,
    categoryName,
    pre,
    next
  } = data.msg;

  return {
    info: {
      id,
      title,
      content,
      publishTime,
      updateTime,
      source,
      author,
      watchTimes,
      thumbnail,
      categoryId,
      categoryName,
      pre,
      next
    }
  };
};
