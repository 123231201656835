import {Global, http, Method,} from "./api-util";
import {isTest} from "./commonApi";
import floorList from './moke-data/floorList.json'
import guessYouLike from "./moke-data/guessYouLike.json";
import * as Api from "./api";

export async function fetchSelfFloor({page, size}) {
    return http({
        url: Global.BASE_URL + '/api/web/home/self-floor',
        method: Method.GET,
        params: {
            page,
            size
        }
    }).then(res => res.data)
}

export async function fetchFloorList({page, size}) {
    if (isTest) {
        return floorList
    }

    return http({
        url: Global.BASE_URL + '/api/web/home/floor',
        method: Method.GET,
        params: {
            page,
            size
        }
    }).then(res => res.data)
}

export async function fetchGuessYouLike() {
    if (isTest) {
        return guessYouLike.lookAroundGoodsList;
    } else {
        const {list, totalNum} = await Api.recommendList({
            pageNum: 1,
            pageSize: 5
        })
        return list
    }
}

export async function fetchLookAround() {
    const {list, totalNum} = await Api.recommendList({
        pageNum: 1,
        pageSize: 10
    })
    return list
}