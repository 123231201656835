import {makeAutoObservable} from 'mobx';

class Register {
    username = '';
    password = '';
    password2 = '';
    verify = '';


    constructor() {
        makeAutoObservable(this)
    }
}

const register = new Register();

export default register;
