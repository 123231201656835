import { combineReducers } from 'redux';

import type from './action-type';

const reducer = {};

export const Input = {
  password: 'password',
  password2: 'password2',
  verify: 'verify'
};

const input = {
  [Input.password]: '',
  [Input.password2]: '',
  [Input.verify]: ''
};
reducer.input = (state = input, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.INIT:
      return input;
    case type.SET_INPUT:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default combineReducers({
  ...reducer
});
