import {Global, http, Method,} from "./api-util";


export async function categoryList({name}) {
    return childCategoryList({pid: 0, name})
}

export async function childCategoryList({pid, name}) {
    return http({
        method: Method.GET,
        url: Global.BASE_URL + '/api/category',
        params: {
            pid,
            name,
            checked: true
        }
    }).then(res => res.data)
}

export async function fetchCategoryTree({id}) {
    return http({
        method: Method.GET,
        url: Global.BASE_URL + `/api/category/tree/${id}`
    }).then(res => res.data)
}

export async function fetchCategoryTreeList({pid}) {
    return http({
        method: Method.GET,
        url: Global.BASE_URL + '/api/category/tree/list',
        params: {
            pid
        }
    }).then(res => res.data)
}