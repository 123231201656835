import * as ActionType from "utils/ActionType";

const type = {
  INIT: "INIT",
  SET_GOODS_CATEGORY_LIST: "SET_GOODS_CATEGORY_LIST",
  SET_CATEGORY_TYPE: "SET_CATEGORY_TYPE",
  SET_HAS_SELF: "SET_HAS_SELF",
  SET_MENU_TYPE: "SET_MENU_TYPE"
};

ActionType.handleType({ type, namespace: "app/" });

export default type;
