import {
    http,
    Header,
    ContentType,
    qs,
    GetFormData,
    StatusCode,
    Global,
    Method
} from './api-util';


export async function secKillList({page, size}) {
    return http({
        url: Global.BASE_URL + '/api/sec-kill',
        method: Method.GET,
        params: {
            page,
            size
        }
    }).then(res => res.data)
}


/**
 * 首页秒杀商品接口
 * @param {*} param0
 */
export const homeSecKillGoodsList = async ({pageSize, pageNum}) => {
    return http({
        method: Method.POST,
        url: Global.BASE_URL + '/sec/kill/list',
        data: {
            page: pageNum,
            size: pageSize
        }
    }).then(({data}) => {
        if (data.code !== 0) {
            return {
                list: []
            }
        }

        const list = data.msg.map(item => {
            const {
                goodsId: id,
                startTime,
                endTime,
                goodsName,
                originPrice,
                currentPrice,
                mainPic: img,
                totalStocks: stock,
                secKillPrice,
                shopId,
                pddShop
            } = item;

            return {
                id,
                startTime,
                endTime,
                goodsName,
                originPrice,
                currentPrice,
                img,
                stock,
                secKillPrice,
                shopId,
                pddShop
            };
        });

        return {
            list
        };
    }).catch(err => {
        if (err === '拼多多') {
            return Promise.resolve({list: []})
        } else if (err === "网络错误") {
            return homeSecKillGoodsList({pageSize, pageNum});
        }
    });

};

export const oldSecKillList = async ({pageSize, pageNum, keyword}) => {
    return http({
        method: Method.POST,
        url: Global.BASE_URL + '/sec/kill/list',
        data: {
            page: pageNum,
            size: pageSize,
            keyword
        }
    }).then(({data}) => {
        const list = data.msg.map(item => {
            const {
                goodsId: id,
                startTime,
                endTime,
                goodsName,
                originPrice,
                currentPrice,
                mainPic,
                totalStocks,
                secKillPrice,
                shopId,
                pddShop
            } = item;

            return {
                id,
                startTime,
                endTime,
                goodsName,
                originPrice,
                currentPrice,
                mainPic,
                totalStocks,
                secKillPrice,
                shopId,
                pddShop
            };
        });

        return {list};
    }).catch(err => {
        if (err === '拼多多') {
            return Promise.resolve({list: []})
        } else if (err === "网络错误") {
            return oldSecKillList({pageSize, pageNum, keyword})
        }
    });

};
