import {combineReducers} from 'redux';
import type from './action-type';
import GoodsType from "../../enum/GoodsType";

const reducer = {};

reducer.keyword = (state = '', action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_KEYWORD:
            return payload;
        default:
            return state;
    }
};

reducer.goodsType = (state = GoodsType.ALL, action) => {
    const {payload} = action

    switch (action.type) {
        case type.SET_GOODS_TYPE:
            return payload;
        default:
            return state;
    }
}

reducer.goodsList = (state = [], action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_GOODS_LIST:
            return payload;
        case type.INIT:
            return [];
        default:
            return state;
    }
};

reducer.totalNum = (state = 0, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_TOTAL_NUM:
            return payload;
        case type.INIT:
            return 0;
        default:
            return state;
    }
};

reducer.condition = (state = null, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_CONDITION:
            return payload;
        case type.INIT:
            return null;
        default:
            return state;
    }
};

reducer.fromPrice = (state = 0, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_FROM_PRICE:
            return payload;
        case type.INIT:
            return 0;
        default:
            return state;
    }
};

reducer.toPrice = (state = 0, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_TO_PRICE:
            return payload;
        case type.INIT:
            return 0;
        default:
            return state;
    }
};

reducer.categoryId = (state = 0, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_CATEGORY_ID:
            return payload;
        case type.INIT:
            return 0;
        default:
            return state;
    }
};

reducer.isNew = (state = null, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_IS_NEW:
            return payload;
        case type.INIT:
            return null;
        default:
            return state;
    }
};

reducer.isHot = (state = null, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_IS_HOT:
            return payload;
        case type.INIT:
            return null;
        default:
            return state;
    }
};

export const page = {
    size: 60,
    num: 1
};
reducer.page = (state = page, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_PAGE_SIZE:
            return {...state, size: payload};
        case type.SET_PAGE_NUM:
            return {...state, num: payload};
        case type.INIT:
            return page;
        default:
            return state;
    }
};

reducer.categoryList = (state = [], action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_CATEGORY_LIST:
            return payload;
        case type.INIT:
            return [];
        default:
            return state;
    }
};

reducer.selectedCategoryList = (state = [], action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_SELECTED_CATEGORY_LIST:
            return payload;
        case type.PUSH_SELECTED_CATEGORY_LIST:
            return [...state, payload];
        case type.TRUNCATE_SELECTED_CATEGORY_LIST: {
            const newState = [...state];
            const {index} = payload;

            newState.splice(index, state.length - index);

            return newState;
        }
        case type.INIT:
            return [];
        default:
            return state;
    }
};

reducer.recommendKeywordList = (state = [], action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_RECOMMEND_KEYWORD_LIST:
            return payload;
        case type.INIT:
            return [];
        default:
            return state;
    }
};

reducer.recommendList = (state = [], action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.INIT:
            return [];
        case type.SET_RECOMMEND_LIST:
            return payload;
        default:
            return state;
    }
};

reducer.guessList = (state = [], action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.INIT:
            return [];
        case type.SET_GUESS_LIST:
            return payload;
        default:
            return state;
    }
};

export default combineReducers({...reducer});
