import * as ActionType from 'utils/ActionType';

const type = {
  INIT: 'INIT',
  SET_INPUT: 'SET_INPUT'
};

ActionType.handleType({ type, namespace: 'user/modify-password' });

export default type;
