import {
    http,
    Header,
    ContentType,
    qs,
    GetFormData,
    StatusCode,
    Global,
    Method
} from './api-util';

/**
 * 关于我们
 */
export const aboutUs = async () => {
    const {data} = await http({
        method: Method.GET,
        url: process.env.PUBLIC_URL + '/json/about-us.json'
    });

    return {info: data};
};

/**
 * app
 */
export const app = async () => {
    const {data} = await http({
        method: Method.GET,
        url: process.env.PUBLIC_URL + '/json/app.json'
    });

    return {info: data};
};

/**
 * home
 */
export const home = async () => {
    const {data} = await http({
        method: Method.GET,
        url: process.env.PUBLIC_URL + '/json/home.json'
    });

    return {info: data};
};

/**
 * contact
 */
export const contact = async () => {
    const {data} = await http({
        method: Method.GET,
        url: process.env.PUBLIC_URL + '/json/contact.json'
    });

    return data;
};

/**
 * 网站有关信息
 */
export const website = async () => {
    const {data} = await http({
        method: Method.GET,
        url: process.env.PUBLIC_URL + '/json/website.json'
    });

    return {info: data};
};

export const recordUrl = () => {
    return http({
        method: Method.GET,
        url: process.env.PUBLIC_URL + '/json/record-url.json'
    }).then(res=>res.data)
        .then(data=>data.recordUrl)
}

export const recordTime = () => {
    return http({
        method: Method.GET,
        url: process.env.PUBLIC_URL + '/json/record-time.json'
    }).then(res=>res.data)
        .then(data=>data.recordTime)
}