import {
  http,
  Header,
  ContentType,
  qs,
  GetFormData,
  StatusCode,
  Global,
  Method
} from './api-util';

export const seoInfo = async () => {
  const { data } = await http({
    method: Method.GET,
    url: Global.BASE_URL + '/web/page/info'
  });

  const info = data.msg;

  return {info};
};
