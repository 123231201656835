import * as ActionType from 'utils/ActionType';

const type = {
  INIT: 'INIT',
  SET_HOT_GOODS_LIST: 'SET_HOT_GOODS_LIST',
  SET_HOT_TOTAL_NUM: 'SET_HOT_TOTAL_NUM',
  SET_HOT_PAGE_SIZE: 'SET_HOT_PAGE_SIZE',
  SET_HOT_PAGE_NUM: 'SET_HOT_PAGE_NUM',
  SET_NEW_GOODS_LIST: 'SET_NEW_GOODS_LIST',
  SET_LIMIT_TIME_GOODS_LIST: 'SET_LIMIT_TIME_GOODS_LIST',
  SET_RECOMMEND_GOODS_LIST: 'SET_RECOMMEND_GOODS_LIST',
  SET_RECOMMEND_PAGE_SIZE: 'SET_RECOMMEND_PAGE_SIZE',
  SET_RECOMMEND_PAGE_NUM: 'SET_RECOMMEND_PAGE_NUM',
  SET_HAS_COLLECT: 'SET_HAS_COLLECT',
  SET_COLLECT_ID: 'SET_COLLECT_ID',
  SET_SHOP_INFO: 'SET_SHOP_INFO',
  SET_PDD_SHOP: 'SET_PDD_SHOP',
  SET_SHOP_ID: 'SET_SHOP_ID'
};

ActionType.handleType({ type, namespace: 'shop/' });

export default type;
