import React from "react";
import * as css from "./css/loading";

function Loading(props) {
  const { className } = props;
  return (
    <css.Loading className={className}>
      <img
        className="img-loading"
        src={process.env.PUBLIC_URL + "/svg-loaders/bars.svg"}
        alt=""
      />
    </css.Loading>
  );
}

export default Loading;
