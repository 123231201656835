import {computed, makeAutoObservable} from "mobx";
import Collect from "enum/Collect";

import GoodsType from "enum/GoodsType";
import * as Api from "api/api";

class GoodsInfoStore {
    goods = null;
    skuList = [];
    currentSku = null;
    hasCollect = false;
    collectId = 0;
    goodsAttributeList = [];
    specificationMapList = [];
    count = 1;
    recommendList = [];
    lookAroundList = [];
    promotionUrl = "";
    description = "";
    isSecKill = false;
    secKill = {
        hour: 0,
        minute: 0,
        second: 0
    }
    loading = false;


    async addCollect({id}) {
        const {collectId} = await Api.addCollect({id, type: Collect.GOODS});
        this.collectId = collectId;
        this.hasCollect = true;
    }


    async cancelCollect({collectId}) {
        await Api.cancelCollect({collectId});
        this.hasCollect = false;
        this.collectId = 0;
    }


    async fetchGoodsInfo({goodsId, goodsType}) {
        try {
            this.loading = true;

            let {
                skuList,
                goods,
                hasCollect,
                collectId,
                goodsAttributeList,
                specificationMapList,
                goodsPromotionUrl
            } = await Api.goodsInfo({goodsId, type:goodsType});


            let imgList = [];
            if (skuList && skuList.length) {

                let currentSku = skuList.find(sku => sku.id === goods.primaryProductId);

                /**
                 * 设置规格
                 */
                let selectedSpecificationIdList = currentSku.goodsSpecificationIds
                    .split("_")
                    .map(id => Number(id));

                let mapList = [];
                for (let key in specificationMapList) {
                    let specificationList = specificationMapList[key];

                    let map = {};
                    map.list = [...specificationList];
                    map.currentIndex = 0;

                    for (let i = 0; i < specificationList.length; i++) {
                        if (selectedSpecificationIdList.indexOf(specificationList[i].id) !== -1) {
                            map.currentIndex = i;
                            break;
                        }
                    }

                    mapList[key] = map;
                }

                this.skuList = skuList;
                this.currentSku = currentSku;
                this.specificationMapList = {...mapList};
                this.hasCollect = hasCollect;
                this.collectId = collectId;
            }


            if (goods.description) {
                this.description = goods.description;
            }
            if (goodsPromotionUrl) {
                this.promotionUrl = goodsPromotionUrl;
            }

            if (goods) {
                this.goods = goods;
            }
            if (goodsAttributeList) {
                this.goodsAttributeList = goodsAttributeList;
            }
        } catch (error) {
        } finally {
            this.loading = false;
        }
    }


    selectSpecification({key, index}) {
        let specificationMapList = {...this.specificationMapList};
        specificationMapList[key].currentIndex = index;
        this.specificationMapList = specificationMapList;

        let currentSpecificationIdList = [];
        for (let key in specificationMapList) {
            let value = specificationMapList[key];
            currentSpecificationIdList.push(value.list[value.currentIndex].id);
        }

        this.currentSku = this.skuList.find(sku => {
            let skuSpecificationIdList = sku.goodsSpecificationIds.split("_").map(item => Number(item));

            let isOk = true;
            currentSpecificationIdList.forEach(currentSpecificationId => {
                if (!skuSpecificationIdList.includes(currentSpecificationId)) {
                    isOk = false;
                }
            })

            return isOk;
        })
    }


    async fetchRecommendList() {
        let {list} = await Api.recommendList({pageSize: 12, pageNum: 1});

        list = list.splice(0, 12);

        this.recommendList = list;
    }


    async fetchLookAroundList() {
        let {list} = await Api.recommendList({pageSize: 15, pageNum: 1});

        list = list.splice(0, 15);

        this.lookAroundList = list;
    }

    @computed get imgList() {

        let imgList = [];

        /*
        * 填充sku的图片列表
        * */
        if (this.currentSku?.skuPicList?.length) {
            imgList = [...this.currentSku.skuPicList?.split(";")];
        }

        /*
        * 填充商品的图片列表
        * */
        if (this.goods?.imgList?.length) {
            imgList = [...imgList, ...this.goods.imgList.split(";")];
        }
        if (this.goods?.mainPic) {
            imgList = [...imgList, this.goods.mainPic]
        }

        return imgList;
    }



    init() {
        this.goods = null;
        this.currentSku = null;
        this.hasCollect = false;
        this.collectId = 0;
        this.goodsAttributeList = [];
        this.specificationMapList = [];
        this.count = 1;
        this.recommendList = [];
        this.lookAroundList = [];
        this.promotionUrl = "";
        this.description = "";
        this.isSecKill = false;
        this.secKill = {
            hour: 0,
            minute: 0,
            second: 0
        }
        this.loading = false;
    }


    constructor() {
        makeAutoObservable(this)
    }
}

export default new GoodsInfoStore();