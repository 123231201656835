import {
  http,
  Header,
  ContentType,
  qs,
  GetFormData,
  StatusCode,
  Global,
  Method
} from './api-util';

/**
 * 获取省份列表
 */
export const provinceList = () => {
  return http({
    method: Method.GET,
    url: Global.BASE_URL + '/area/getAll'
  }).then(res => res.data)
      .then(data => data.list);
};

/**
 * 获取地址列表
 */
export const areaList = ({parentId}) => {
  return http({
    method: Method.GET,
    url: Global.BASE_URL + '/area/getByParentId',
    params: {
      parentId
    }
  }).then(res => res.data)
      .then(data => data.list);
};
