import {
  http,
  Header,
  ContentType,
  qs,
  GetFormData,
  StatusCode,
  Global,
  Method
} from "./api-util";

import Collect from "enum/Collect";

export const shopStreetList = async ({
  pageSize,
  pageNum,
  goodsNum,
  type,
  descSort,
  servSort,
  lgstSort
}) => {
  // test0 可选值[’0‘,’1‘]，默认是’0‘。’0‘代表正式环境使用; ’1‘代表测试环境使用,测试环境返回的信息是店铺中商品的名字,用以判断传入的kw是否是合适的;
  let test = 0;

  const { data } = await http({
    method: Method.GET,
    url: Global.BASE_URL + "/shop/street/pdd/merchant/list",
    params: {
      page: pageNum,
      size: pageSize,
      test,
      goodsSize: goodsNum,
      merchant_type: type,
      avgDesc: descSort,
      avgServ: servSort,
      avgLgst: lgstSort
    }
  });

  const list = data.msg.objectList;
  const { totalNum } = data.msg;

  return { list, totalNum };
};

export const collectShop = async ({ shopId, pddShop }) => {
  const { data } = await http({
    method: Method.POST,
    url: Global.BASE_URL + "/collect/add",
    data: {
      fid: shopId,
      type: Collect.SHOP,
      pddShop
    }
  });

  const { collectId } = data;

  return {
    collectId
  };
};

export const cancelCollectShop = async ({ collectId }) => {
  await http({
    method: Method.DELETE,
    url: Global.BASE_URL + "/collect/del",
    params: {
      id: collectId
    }
  });
};

export const shopInfo = async ({ pageNum, pageSize, shopId, pddShop }) => {
  let list, shopInfo, totalNum, collectId, hasCollect, shopSoldNum, pddShopType;
  if (pddShop) {
    const { data } = await http({
      method: Method.POST,
      url: Global.BASE_URL + "/mall/mall/goods/list",
      data: {
        page_number: pageNum,
        page_size: pageSize,
        mall_id: shopId
      }
    });

    list = data.goodsList.map(item => {
      const {
        id,
        name,
        originPrice,
        currentPrice,
        shopId,
        soldNum,
        mainPic,
        hasCollect,
        collectId,
        categoryId,
        isHot,
        pddShop
      } = item;

      return {
        id,
        name,
        originPrice,
        currentPrice,
        shopId,
        soldNum,
        mainPic,
        hasCollect,
        collectId,
        categoryId,
        isHot,
        pddShop
      };
    });

    shopInfo = {
      id: Number(data.mallInfo.shopId),
      img: data.mallInfo.pic,
      name: data.mallInfo.shopName
    };
    totalNum = data.totalNum;
    collectId = data.mallInfo.collectId;
    hasCollect = data.mallInfo.hasCollect;
    shopSoldNum = data.mallInfo.shopSoleNum;
    pddShopType = data.mallInfo.pddShopType;
  } else {
    const { data } = await http({
      method: Method.GET,
      url: Global.BASE_URL + "/shop/street/goods/list",
      params: {
        page: pageNum,
        size: pageSize,
        shopId
        // isHot,
        // isNew
      }
    });

    shopInfo = {
      id: data.shopInfo.id,
      name: data.shopInfo.name,
      img: data.shopInfo.topLogo
    };
    totalNum = data.totalNum;
    collectId = data.shopCollectId;
    hasCollect = data.shopHasCollect;
    shopSoldNum = data.shopSoldNum;

    list = data.goodsList.map(item => {
      const {
        id,
        name,
        originPrice,
        currentPrice,
        shopId,
        soldNum,
        mainPic,
        hasCollect,
        collectId,
        categoryId,
        isHot,
        pddShop
      } = item;

      return {
        id,
        name,
        originPrice,
        currentPrice,
        shopId,
        soldNum,
        mainPic,
        hasCollect,
        collectId,
        categoryId,
        isHot,
        pddShop
      };
    });
  }

  return {
    list,
    shopInfo,
    totalNum,
    collectId,
    hasCollect,
    shopSoldNum,
    pddShopType
  };
};

// export const shopInfo = async ({ pageNum, pageSize, shopId, pddShop }) => {
//   let list, shopInfo, totalNum, collectId, hasCollect;
//   if (pddShop) {
//     const { data } = await http({
//       method: Method.POST,
//       url: Global.BASE_URL + '/mall/mall/goods/list',
//       data: {
//         page_number: pageNum,
//         page_size: pageSize,
//         mall_id: shopId
//       }
//     });

//     list = data.goodsList.map(item => {
//       const {
//         id,
//         name,
//         originPrice,
//         currentPrice,
//         shopId,
//         soldNum,
//         mainPic,
//         hasCollect,
//         collectId,
//         categoryId,
//         isHot,
//         pddShop
//       } = item;

//       return {
//         id,
//         name,
//         originPrice,
//         currentPrice,
//         shopId,
//         soldNum,
//         mainPic,
//         hasCollect,
//         collectId,
//         categoryId,
//         isHot,
//         pddShop
//       };
//     });

//     totalNum = data.totalNum;
//     {
//       const { shopName: name, pic: img, shopId: id } = data.mallInfo;

//       shopInfo = { name, img, id };
//     }
//   } else {
//     const { data } = await http({
//       method: Method.GET,
//       url: Global.BASE_URL + '/shop/street/goods/list',
//       params: {
//         page: pageNum,
//         size: pageSize,
//         shopId
//         // isHot,
//         // isNew
//       }
//     });
//     {
//       const {
//         id,
//         userId,
//         name,
//         thumbnail,
//         topLogo: img,
//         cover,
//         time
//       } = data.shopInfo;

//       shopInfo = {
//         id,
//         name,
//         img
//       };
//     }

//     totalNum = data.totalNum;
//     collectId = data.collectId;
//     hasCollect = data.hasCollect;

//     list = data.goodsList.map(item => {
//       const {
//         id,
//         name,
//         originPrice,
//         currentPrice,
//         shopId,
//         soldNum,
//         mainPic,
//         hasCollect,
//         collectId,
//         categoryId,
//         isHot,
//         pddShop
//       } = item;

//       return {
//         id,
//         name,
//         originPrice,
//         currentPrice,
//         shopId,
//         soldNum,
//         mainPic,
//         hasCollect,
//         collectId,
//         categoryId,
//         isHot,
//         pddShop
//       };
//     });
//   }

//   return {
//     list,
//     shopInfo,
//     totalNum,
//     collectId,
//     hasCollect
//   };
// };
