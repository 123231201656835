import * as ActionType from 'utils/ActionType';

const type = {
  INIT: 'INIT',
  SET_MAP: 'SET_MAP',
  SET_PAGE_SIZE: 'SET_PAGE_SIZE',
  SET_PAGE_NUM: 'SET_PAGE_NUM'
};

ActionType.handleType({
  type,
  namespace: 'user/track/'
});

export default type;
