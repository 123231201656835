const type = {
  SUCCESS: {
    value: 200,
    message: '成功'
  },
  PASSWORD_ERROR: {
    value: 430,
    message: '密码错误'
  },
  ACCOUNT_NOT_EXIST: {
    value: 431,
    message: '账户不存在'
  },
  MISS_PARAMETER: {
    value: 421,
    message: '参数缺失'
  },
  INVALID_PARAMETER: {
    value: 422,
    message: '参数无效'
  },
  RESOURCE_NOT_FOUND: {
    value: 423,
    message: '请求资源不存在'
  },
  REPEAT_OPERATION: {
    value: 424,
    message: '重复操作'
  },
  INVALID_OPERATION: {
    value: 425,
    message: '无效操作'
  },
  NOT_LOGIN: {
    value: 426,
    message: '未登录'
  },
  getByValue(value) {
    for (let key in type) {
      if (type[key].value === value) {
        return type[key];
      }
    }
  }
};

Object.defineProperty(type, 'getByValue', {
  enumerable: false
});

export default type;
