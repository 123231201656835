import type from './action-type';
import { combineReducers } from 'redux';
import account from './account/reducer';
import modifyPassword from './modify-password/reducer';
import goodsCollect from './goods-collect/reducer';
import shopCollect from './shop-collect/reducer';
import track from './track/reducer';

const reducer = {};

reducer.shopId = (state = 0, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_SHOP_ID:
      return payload;
    case type.INIT:
      return 0;
    default:
      return state;
  }
};

reducer.username = (state = '', action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_USERNAME:
      return payload;
    case type.INIT:
      return '';
    default:
      return state;
  }
};

reducer.currentMenuIndex = (state = 0, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_CURRENT_MENU_INDEX:
      return payload;
    case type.INIT:
      return 0;
    default:
      return state;
  }
};

reducer.recommendList = (state = [], action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_RECOMMEND_LIST:
      return payload;
    case type.INIT:
      return [];
    default:
      return state;
  }
};

const recommendPage = {
  size: 10,
  num: 1
};
reducer.recommendPage = (state = recommendPage, action = {}) => {
  const { payload } = action;

  switch (type.action) {
    case type.SET_RECOMMEND_PAGE_SIZE:
      return { ...state, size: payload };
    case type.SET_RECOMMEND_PAGE_NUM:
      return { ...state, num: payload };
    case type.INIT:
      return recommendPage;
    default:
      return state;
  }
};

export default combineReducers({
  ...reducer,
  account,
  modifyPassword,
  goodsCollect,
  shopCollect,
  track
});
