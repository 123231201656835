import {makeAutoObservable} from 'mobx'

class OrderStore {
    id = 0;
    sn = '';
    price = 0;
    status = undefined;
    createTime = 0;
    delivery = undefined;
    orderAddress = undefined;
    orderProductList = [];
    payType = undefined;
    payTypeList = [];
    currentPayTypeId = 0;
    afterSale = undefined;
    contact = undefined;


    constructor() {
        makeAutoObservable(this)
    }
}

const orderStore = new OrderStore()

export default orderStore
