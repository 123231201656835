import { combineReducers } from 'redux';
import type from './action-type';

const reducer = {};

reducer.list = (state = null, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.INIT:
      return null;
    case type.SET_LIST:
      return payload;
    default:
      return state;
  }
};

const shopPage = {
  size: 5,
  num: 1
};
reducer.shopPage = (state = shopPage, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.INIT:
      return shopPage;
    case type.SET_SHOP_PAGE_SIZE:
      return { ...state, size: payload };
    case type.SET_SHOP_PAGE_NUM:
      return { ...state, num: payload };
    default:
      return state;
  }
};

const goodsPage = {
  size: 15,
  num: 1
};
reducer.goodsPage = (state = goodsPage, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.INIT:
      return goodsPage;
    case type.SET_GOODS_PAGE_SIZE:
      return { ...state, size: payload };
    case type.SET_GOODS_PAGE_NUM:
      return { ...state, num: payload };
    default:
      return state;
  }
};

export default combineReducers({ ...reducer });
