import {makeAutoObservable} from 'mobx';
import * as Api from "api/api";

class Shop {
    companyName = '';
    officialWebsite = '';
    saleroom = '';
    hasExperience = false;
    shopWebsite = '';
    personNumber = 0;
    goodsNumber = 0;
    predictAveragePrice = 0;
    stock = 0;
    warehouseAddress = '';
    generalLogistics = '';
    erpType = 0;
    thirdPartnarCompany = '';
    selectOurWarehouse = false;
    selectOurLogistics = false;
    openSaleQualification = false;
    receivedInvite = 0;
    goodsNumberList = [];
    predictAveragePriceList = [];
    stockList = [];
    erpTypeList = [];
    receivedInviteList = [];


    async fetchGoodsNumberList() {
        try {
            const {list} = await Api.goodsNumberList();

            this.goodsNumberList = list.map(item => {
                const {id, numberScope: value} = item;

                return {
                    id,
                    value
                };
            });
            this.predictAveragePriceList = list.map(item => {
                const {id, numberScope: value} = item;

                return {
                    id,
                    value
                };
            });
        } catch (error) {
        }
    }

    async fetchStockList() {
        try {
            const {list} = await Api.stockList();

            this.stockList = list.map(item => {
                const {id, warehouse: value} = item;

                return {
                    id,
                    value
                };
            });
        } catch (error) {
        }
    }

    async fetchErpTypeList() {
        try {
            const {list} = await Api.erpTypeList();

            this.erpTypeList = list.map(item => {
                const {id, erp: value} = item;

                return {
                    id,
                    value
                };
            });
        } catch (error) {
        }
    }

    async fetchReceivedInvitedList() {
        try {
            const {list} = await Api.receivedInviteList();

            this.receivedInviteList = list.map(item => {
                const {id, stands: value} = item;

                return {
                    id,
                    value
                };
            });
        } catch (error) {
        }
    }

    fetchData() {
        this.fetchGoodsNumberList();
        this.fetchStockList();
        this.fetchErpTypeList();
        this.fetchReceivedInvitedList();
    }


    constructor() {
        makeAutoObservable(this)
    }
}

let shop = new Shop();

export default shop;
