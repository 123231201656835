import MerchantType from "enum/MerchantType";
import {makeAutoObservable} from "mobx";

class ShopStreetStore {
    list = [];
    keyword = '';
    pageSize = 24;
    pageNum = 1;
    totalNum = 0;
    type = MerchantType.All.value;
    descSort = null;
    servSort = null;
    lgstSort = null;
    loading = false;

    resetSort() {
        this.descSort = null;
        this.servSort = null;
        this.lgstSort = null;
    };


    constructor() {
        makeAutoObservable(this)
    }
}

export default new ShopStreetStore();