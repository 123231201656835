import {Global, http, Method} from './api-util';


export async function defaultLive() {
    return http({
        method: Method.GET,
        url: Global.BASE_URL + '/api/live/default'
    }).then(res => res.data)
}

export async function defaultLiveContext() {
    return http({
        method: Method.GET,
        url: Global.BASE_URL + '/api/live/default/context'
    }).then(res => res.data)
}