import {
  http,
  Header,
  ContentType,
  qs,
  GetFormData,
  StatusCode,
  Global,
  Method
} from './api-util';

/**
 * 商品数量列表
 */
export const goodsNumberList = async () => {
  const { data } = await http({
    method: Method.GET,
    url: Global.BASE_URL + '/merchant/num/scope/list'
  });

  const list = data.msg;

  return {
    list
  };
};

/**
 * 平均客单价列表
 */
// export const predictAveragePriceList = async () => {
//   const { data } = await http({
//     method: Method.GET,
//     url: Global.BASE_URL + '/merchant/num/scope/list'
//   });

//   const list = data.msg;

//   return {
//     list
//   };
// };

/**
 * 仓库列表
 */
export const stockList = async () => {
  const { data } = await http({
    method: Method.GET,
    url: Global.BASE_URL + '/merchant/warehouse/list'
  });

  const list = data.msg;

  return {
    list
  };
};

/**
 * erp类型列表
 */
export const erpTypeList = async () => {
  const { data } = await http({
    method: Method.GET,
    url: Global.BASE_URL + '/merchant/erp/list'
  });

  const list = data.msg;

  return { list };
};

/**
 * 接受邀请列表
 */
export const receivedInviteList = async () => {
  const { data } = await http({
    method: Method.GET,
    url: Global.BASE_URL + '/merchant/vertical/stands/list'
  });

  const list = data.msg;

  return { list };
};

/**
 * 店铺类型列表
 */
export const shopTypeList = async () => {
  const { data } = await http({
    method: Method.GET,
    url: Global.BASE_URL + '/merchant/shop/type/list'
  });

  const list = data.msg;

  return { list };
};

/**
 * 获取招商入驻状态信息
 */
export const merchantAuditStatus = async () => {
  const { data } = await http({
    method: Method.GET,
    url: Global.BASE_URL + '/merchant/investment/status/query'
  });

  const {
    expectShopName: shopName,
    shopLoginName: shopUsername,
    status
  } = data;

  return {
    info: {
      shopName,
      shopUsername,
      status
    }
  };
};

export const addMerchant = async ({
  name,
  telPhone,
  email,
  gender,
  companyName,
  legalPersonName,
  idCard,
  legalPersonIdPicFileList,
  companyLocationProvince,
  companyLocationCity,
  companyLocationRegion,
  companyDetailLocation,
  establishTime,
  businessEndTime,
  businessStartTime,
  registeredCapital,
  businessScope,
  businessLicensePicListFile,
  companyPhone,
  companyEmergencyContact,
  companyEmergencyContactPhone,
  organizationCode,
  companyWebsiteAddress,
  salesIneLatestYear,
  similarElectronicWebsiteExperience,
  shopAddress,
  onlineShopOperatorsNumber,
  goodsNumberForSaleOnline,
  averageUnitPrice,
  warehouse,
  warehouseAddress,
  commonLogisticsCompany,
  erpType,
  agentOperatingCompanyName,
  traderSandStorage,
  liquorMallLogistics,
  openDistributionQualification,
  verticalStanding,
  expectedShopType,
  mainCategory,
  detailCategoryIds,
  hasCategoryIndustryQualification,
  categoryIndustryQualification,
  categoryDescribeKeyword,
  expectShopName,
  shopLoginName,
  shopLoginPassword,
  shopSuffix
}) => {
  const param = {
    name,
    telPhone,
    email,
    gender,
    companyName,
    legalPersonName,
    idCard,
    legalPersonIdPicFileList,
    companyLocationProvince,
    companyLocationCity,
    companyLocationRegion,
    companyDetailLocation,
    establishTime,
    businessEndTime,
    businessStartTime,
    registeredCapital,
    businessScope,
    businessLicensePicListFile,
    companyPhone,
    companyEmergencyContact,
    companyEmergencyContactPhone,
    organizationCode,
    companyWebsiteAddress,
    salesIneLatestYear,
    similarElectronicWebsiteExperience,
    shopAddress,
    onlineShopOperatorsNumber,
    goodsNumberForSaleOnline,
    averageUnitPrice,
    warehouse,
    warehouseAddress,
    commonLogisticsCompany,
    erpType,
    agentOperatingCompanyName,
    traderSandStorage,
    liquorMallLogistics,
    openDistributionQualification,
    verticalStanding,
    expectedShopType,
    mainCategory,
    detailCategoryIds,
    hasCategoryIndustryQualification,
    categoryIndustryQualification,
    categoryDescribeKeyword,
    expectShopName,
    shopLoginName,
    shopLoginPassword,
    shopSuffix
  };
  const formData = GetFormData(param);

  await http({
    method: Method.POST,
    url: Global.BASE_URL + '/merchant/investment/in',
    headers: {
      [Headers.ContentType]: ContentType.FormData
    },
    data: formData
  });
};
