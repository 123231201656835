import {
  http,
  Header,
  ContentType,
  qs,
  GetFormData,
  StatusCode,
  Global,
  Method
} from './api-util';

/**
 * 二维码列表
 */
export const qrCodeList = async () => {
  const { data } = await http({
    method: Method.POST,
    url: Global.BASE_URL + '/qr/code/list'
  });

  let list = data.msg;

  //   type：二维码类型 枚举 WEB_QR_CODE(0, "web端二维码"), APP_QR_CODE(1, "移动端二维码"), MINI_APP_QR_CODE(2, "小程序二维码");

  return { list };
};
