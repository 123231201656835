import {handleType} from "utils/ActionType";

let type = {
  INIT: 'INIT',
  SET_LIST: 'SET_LIST',
  SET_PAGE_SIZE: 'SET_PAGE_SIZE',
  SET_PAGE_NUM: 'SET_PAGE_NUM',
  SET_TOTAL_NUM: 'SET_TOTAL_NUM',
  SET_KEYWORD: 'SET_KEYWORD',
  SET_TYPE: 'SET_TYPE',
  SET_DESC_SORT: 'SET_DESC_SORT',
  SET_SERV_SORT: 'SET_SERV_SORT',
  SET_LGST_SORT: 'SET_LGST_SORT'
};

handleType({
    namespace:'shop-street/',
    type
})

export default type;
