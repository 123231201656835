import {
    http,
    Header,
    ContentType,
    qs,
    GetFormData,
    StatusCode,
    Global,
    Method
} from './api-util';

export async function hotSearchList({word}) {
    return http({
        method: Method.GET,
        url: Global.BASE_URL + '/api/hot_search',
        params: {
            word
        }
    }).then(res => res.data)
}