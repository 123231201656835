import React, {lazy, Suspense} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
// swiper css
import "assets/swiper/css/swiper.min.css";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import "./App.scss";

import MyRouter from "router/router";
import Loading from "components/loading/Loading";
// import Index from "pages/index/Index";
// import Login from "pages/login/Login";
// import Register from "pages/register/Register";

const Index = lazy(() => import("pages/index/Index"));
const Home = lazy(() => import('pages/home/Home'))
const Login = lazy(() => import("pages/login/Login"));
const Register = lazy(() => import("pages/register/Register"));

const supportsHistory = "pushState" in window.history;

function App() {
    return (
        <div className="App" id="app">
            <Router forceRefresh={!supportsHistory} basename={MyRouter.basename}>
                <Suspense fallback={<Loading/>}>
                    <Switch>
                        <Route path={MyRouter.Login.path} exact component={Login}/>
                        <Route path={MyRouter.Register.path} component={Register}/>
                        <Route path={[MyRouter.Home.path, '/']} component={Home} exact/>
                        <Route path={MyRouter.Index.path} component={Index}/>
                    </Switch>
                </Suspense>
            </Router>
        </div>
    );
}

export default App;
