import {
  http,
  Header,
  ContentType,
  qs,
  GetFormData,
  StatusCode,
  Global,
  Method
} from './api-util';

/**
 * 帮助中心菜单列表
 */
export const helpMenuList = async () => {
  const { data } = await http({
    method: Method.GET,
    url: Global.BASE_URL + '/help/center/index/category/article'
  });

  const list = data.msg;

  return { list };
};

/**
 * 帮助中心详情
 */
export const helpInfo = async ({ id }) => {
  const { data } = await http({
    method: Method.GET,
    url: Global.BASE_URL + '/help/center/article/info',
    params: {
      id
    }
  });

  const info = data.msg;

  return { info };
};
