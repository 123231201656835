import {makeAutoObservable} from "mobx";

class AddressListStore {
    list = [];
    showAddAddress = false;
    showModifyAddress = false;
    currentAddress = undefined;

    constructor() {
        makeAutoObservable(this)
    }
}

const store = new AddressListStore()

export default store
