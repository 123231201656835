import {Global, http, Method} from "./api-util";

export const orderList = ({pageSize, pageNum, sn, status}) => {
    return http({
        method: Method.GET,
        url: Global.BASE_URL + '/api/order/list',
        params: {
            page: pageNum,
            size: pageSize,
            sn,
            status
        }
    }).then(res => res.data)
        .then((data) => {
            const {objectList: list, totalNum} = data;

            return {
                list, totalNum
            }
        })

}

export const addOrder = ({orderProductList}) => {
    return http({
        method: Method.POST,
        url: Global.BASE_URL + '/api/order',
        data: {
            orderProductList
        }
    }).then(res => res.data);
}

export const cancelOrder = ({id}) => {
    return http({
        method: Method.PUT,
        url: Global.BASE_URL + `/api/order/cancel/${id}`
    })
}

export const orderInfo = ({id}) => {
    return http({
        method: Method.GET,
        url: Global.BASE_URL + `/api/order/${id}`
    }).then(res => res.data);
}

export const payOrder = ({orderId, type}) => {
    return http({
        method: Method.PUT,
        url: Global.BASE_URL + '/api/order/pay',
        data: {
            orderId,
            type
        }
    })
}

export const deleteOrder = ({id}) => {
    return http({
        method: Method.DELETE,
        url: Global.BASE_URL + `/api/order/{id}}`
    })
}

export const modifyOrder = ({orderId, orderAddress}) => {
    return http({
        method: Method.PUT,
        url: Global.BASE_URL + '/api/order',
        data: {
            orderId,
            orderAddress
        }
    })
}

export const buyOrderAgain = ({id}) => {
    return http({
        method: Method.POST,
        url: Global.BASE_URL + `/api/order/buy-again/${id}`
    })
}
