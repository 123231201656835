import {
  http,
  Header,
  ContentType,
  qs,
  GetFormData,
  StatusCode,
  Global,
  Method
} from './api-util';

/**
 * 添加收藏
 * @param {*} param0
 */
export const addCollect = async ({ id, type }) => {
  const { data } = await http({
    method: Method.POST,
    url: Global.BASE_URL + '/collect/add',
    data: {
      fid: id,
      type
    }
  });

  const { collectId } = data;

  return { collectId };
};

/**
 * 取消收藏
 * @param {*} param0
 */
export const cancelCollect = async ({ collectId }) => {
  await http({
    method: Method.DELETE,
    url: Global.BASE_URL + '/collect/del',
    params: {
      id: collectId
    }
  });
};

/**
 * 商品收藏列表
 * @param {*} param0
 */
export const goodsCollectList = async ({
  pageSize,
  pageNum,
  order,
  sortField
}) => {
  const { data } = await http({
    method: Method.POST,
    url: Global.BASE_URL + '/collect/list',
    data: {
      page: pageNum,
      size: pageSize,
      type: 0,
      startTime: undefined,
      endTime: undefined,
      order,
      sortField
    }
  });

  const list = data.msg.objectList.map(item => {
    const {
      id,
      time,
      timeStamp,
      fid,
      type,
      name,
      mainPic,
      currentPrice,
      originPrice,
      categoryId,
      soldNum,
      pddShop,
      top
    } = item;

    return {
      id,
      time,
      timeStamp,
      fid,
      type,
      name,
      mainPic,
      currentPrice,
      originPrice,
      categoryId,
      soldNum,
      pddShop,
      top
    };
  });

  const { totalNum } = data.msg;

  return {
    list,
    totalNum
  };
};

/**
 * 店铺收藏列表
 * @param {*} param0
 */
export const shopCollectList = async ({
  pageSize,
  pageNum,
  goodsPageSize,
  goodsPageNum
}) => {
  const { data } = await http({
    method: Method.POST,
    url: Global.BASE_URL + '/collect/list',
    data: {
      page: pageNum,
      size: pageSize,
      type: 1,
      startTime: undefined,
      endTime: undefined,
      goodsPage: goodsPageSize,
      goodsSize: goodsPageNum
    }
  });

  const list = data.msg.objectList.map(item => {
    const {
      collectNum,
      fid,
      goodsList,
      id:collectId,
      name,
      pddShop,
      shopType,
      thumbnail,
      time,
      timeStamp,
      type
    } = item;

    return {
      collectNum,
      fid,
      goodsList,
      collectId,
      name,
      pddShop,
      shopType,
      thumbnail,
      time,
      timeStamp,
      type
    };
  });

  const { totalNum } = data.msg;

  return {
    list,
    totalNum
  };
};
