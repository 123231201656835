const goodsType = {
    ALL: -1,
    SELF: 0,
    PDD: 1,
    JD: 2
};

export const goodsTypeEnumMap = new Map([
    [goodsType.ALL, '综合'],
    [goodsType.SELF, '自营'],
    [goodsType.PDD, '拼多多'],
    [goodsType.JD, '京东']
])

export default goodsType;
