import {
  http,
  Header,
  ContentType,
  qs,
  GetFormData,
  StatusCode,
  Global,
  Method
} from './api-util';

/**
 * 根据goods和规格获取sku信息
 * @param {*} param0
 */
export const skuInfoByGoodsAndSpecification = async ({
  goodsId,
  specificationIdListStr
}) => {
  const { data } = await http({
    method: Method.GET,
    url: Global.BASE_URL + '/api/goods/specification/sku',
    params: {
      goodsId,
      specificationIds: specificationIdListStr
    }
  });

  const { sku } = data;

  return { sku };
};

export const skuListByGoodsId = async ({ goodsId }) => {
  const { data } = await http({
    method: Method.POST,
    url: Global.BASE_URL + '/product/list/all',
    data: {
      goodsId
    }
  });

  const list = data.msg;

  return { list };
};
