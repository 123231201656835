import { combineReducers } from 'redux';

import type from './action-type';

const reducer = {};

reducer.hotGoodsList = (state = [], action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_HOT_GOODS_LIST:
      return payload;
    case type.INIT:
      return [];
    default:
      return state;
  }
};

reducer.hotTotalNum = (state = 0, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_HOT_TOTAL_NUM:
      return payload;
    case type.INIT:
      return 0;
    default:
      return state;
  }
};

const hotPage = {
  size: 12,
  num: 1
};
reducer.hotPage = (state = hotPage, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_HOT_PAGE_NUM:
      return { ...state, num: payload };
    case type.SET_HOT_PAGE_SIZE:
      return { ...state, size: payload };
    case type.INIT:
      return hotPage;
    default:
      return state;
  }
};

reducer.newGoodsList = (state = [], action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_NEW_GOODS_LIST:
      return payload;
    case type.INIT:
      return [];
    default:
      return state;
  }
};

reducer.limitTimeGoodsList = (state = [], action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_LIMIT_TIME_GOODS_LIST:
      return payload;
    case type.INIT:
      return [];
    default:
      return state;
  }
};

reducer.recommendGoodsList = (state = [], action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_RECOMMEND_GOODS_LIST:
      return payload;
    case type.INIT:
      return [];
    default:
      return state;
  }
};

const recommendPage = {
  size: 4,
  num: 1
};
reducer.recommendPage = (state = recommendPage, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_RECOMMEND_PAGE_NUM:
      return { ...state, num: payload };
    case type.SET_RECOMMEND_PAGE_SIZE:
      return { ...state, size: payload };
    case type.INIT:
      return recommendPage;
    default:
      return state;
  }
};

reducer.hasCollect = (state = false, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_HAS_COLLECT:
      return payload;
    case type.INIT:
      return false;
    default:
      return state;
  }
};

reducer.collectId = (state = 0, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_COLLECT_ID:
      return payload;
    case type.INIT:
      return 0;
    default:
      return state;
  }
};

reducer.shopInfo = (state = null, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_SHOP_INFO:
      return payload;
    case type.INIT:
      return null;
    default:
      return state;
  }
};

reducer.pddShop = (state = false, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_PDD_SHOP:
      return payload;
    case type.INIT:
      return false;
    default:
      return state;
  }
};

reducer.shopId = (state = -1, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_SHOP_ID:
      return payload;
    case type.INIT:
      return -1;
    default:
      return state;
  }
};

export default combineReducers({ ...reducer });
