import {
  http,
  Header,
  ContentType,
  qs,
  GetFormData,
  StatusCode,
  Global,
  Method,
} from "./api-util";

/**
 * 拼多多商品分类
 * 如果 parentId 为0，则查出顶级分类
 * 如果 parentId 不为0，则查出所有子分类
 * @param {*} param0
 */
export const pddGoodsCategoryListByParentId = async ({
  parentId,
  parentOptId,
}) => {
  const { data } = await http({
    method: Method.GET,
    url: Global.BASE_URL + "/pdd/mall/list/all/opt/byParentId",
    headers: {
      [Header.ContentType]: ContentType.JSON,
    },
    params: {
      parentId,
      parentOptId,
    },
  });

  function handleList(list) {
    return list.map((item) => {
      let {
        optName: name,
        sonOptTree: childList,
        uncheckedIcon: icon,
        icon: iconActive,
      } = item;

      if (childList) {
        childList = handleList(childList);
      }

      return {
        ...item,
        name,
        parentId: item.parentId,
        parentOptId: item.parentOptId,
        isSelf: false,
        icon,
        iconActive,
        childList,
      };
    });
  }

  const list = handleList(data.msg);

  const { hasSelfRunCategory: hasSelfCategory } = data;

  return {
    list,
    hasSelfCategory,
  };
};

/**
 * 拼多多商品分类
 * 如果 parentId 为0，则查出顶级分类
 * 如果 parentId 不为0，则查出所有子分类
 * @param {*} param0
 */
export const pddGoodsCategoryList = async ({ parentId }) => {
  const { data } = await http({
    method: Method.GET,
    url: Global.BASE_URL + "/pdd/mall/list/all/opt",
    headers: {
      [Header.ContentType]: ContentType.JSON,
    },
    params: {
      pid: parentId,
    },
  });

  function handleList(list) {
    return list.map((item) => {
      let {
        optName: name,
        sonOptTree: childList,
        uncheckedIcon: icon,
        icon: iconActive,
      } = item;

      if (childList) {
        childList = handleList(childList);
      }

      return {
        ...item,
        name,
        isSelf: false,
        icon,
        iconActive,
        childList,
      };
    });
  }

  const list = handleList(data.msg);

  const { hasSelfRunCategory: hasSelfCategory } = data;

  return {
    list,
    hasSelfCategory,
  };
};

/**
 * 拼多多商品顶级分类
 * 如果 parentId 为0，则查出顶级分类
 */
export const pddMainGoodsCategoryList = async () => {
  const { data } = await http({
    method: Method.GET,
    url: Global.BASE_URL + "/pdd/mall/list/all/opt",
    headers: {
      [Header.ContentType]: ContentType.JSON,
    },
    params: {
      pid: 0,
    },
  });

  function handleList(list) {
    return list.map((item) => {
      let { optName: name, uncheckedIcon: icon, icon: iconActive } = item;

      return {
        ...item,
        name,
        isSelf: false,
        icon,
        iconActive,
      };
    });
  }

  const list = handleList(data.msg);

  return {
    list,
  };
};

/**
 * 拼多多商品子分类
 * 根据 parentId 获取拼多多商品子分类
 * @param {*} param0
 */
export const pddChildGoodsCategoryList = async ({
  parentId,
  parentOptId,
  isSelect
}) => {
  const { data } = await http({
    method: Method.GET,
    url: Global.BASE_URL + "/pdd/mall/list/all/opt/background",
    params: {
      pid: parentId,
      parentOptId,
      isSelect:!!isSelect,
    },
  });

  const list = data.msg.map((item) => {
    const { optName: name } = item;

    return {
      ...item,
      parentId,
      parentOptId,
      name,
      isSelf: false,
    };
  });

  return {
    list,
  };
};

/**
 * 自营商品所有子分类
 */
export const allChildGoodsCategoryList = async () => {
  const { data } = await http({
    method: Method.GET,
    url: Global.BASE_URL + "/product/category/listCategory/front",
    params: {
      pid: 0,
    },
  });

  function handleList(list) {
    return list.map((item) => {
      let { sonCategoryTree: childList } = item;

      if (childList) {
        childList = handleList(childList);
      }

      return {
        ...item,
        childList,
        isSelf: true,
      };
    });
  }

  const list = handleList(data);

  return {
    list,
  };
};

/**
 * 自营商品子分类
 * 根据 parentId 获取自营商品子分类
 * @param {*} param0
 */
export const childGoodsCategoryList = async ({ parentId }) => {
  const { data } = await http({
    method: Method.GET,
    url: Global.BASE_URL + "/product/category/listCategory/background",
    params: {
      pid: parentId,
    },
  });

  const list = data.map((item) => {
    const { pid: parentId, pic: img } = item;

    return {
      ...item,
      parentId,
      img,
      isSelf: true,
    };
  });

  return {
    list,
  };
};
