export const openFixed = () => {
    // 弹层出现
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';//果然是因为加了fixed，就会自动回滚到顶部
}

export const closeFixed = () => {
    // 弹层关闭
    document.body.style.overflow = 'auto';
    document.body.style.position = 'static';
}