const type = {
  GOODS_CATEGORY: {
    value: 1
  },
  NEWS_CATEGORY: {
    value: 2
  },
  NONE: {
    value: 3
  },
  SEARCH_GOODS_CATEGORY: {
    value: 4
  },
  getByValue(value) {
    for (let key in type) {
      if (type[key].value === value) {
        return type[key];
      }
    }
  }
};

Object.defineProperty(type, 'getByValue', {
  enumerable: false
});

export default type;
