import type from "./action-type";
import { combineReducers } from "redux";
import CategoryType from "enum/CategoryType";
import MenuType from "enum/MenuType";

const reducer = {};

reducer.goodsCategoryList = (state = [], action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_GOODS_CATEGORY_LIST:
      return payload;
    case type.INIT:
      return [];
    default:
      return state;
  }
};

const categoryType = CategoryType.GOODS_CATEGORY.value;

reducer.categoryType = (state = categoryType, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_CATEGORY_TYPE:
      return payload;
    case type.INIT:
      return categoryType;
    default:
      return state;
  }
};

reducer.hasSelf = (state = false, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_HAS_SELF:
      return payload;
    default:
      return state;
  }
};

reducer.menuType = (state = MenuType.GOODS, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_MENU_TYPE:
      return payload;
    default:
      return state;
  }
};

export default combineReducers({ ...reducer });
