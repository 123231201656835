import {makeAutoObservable} from 'mobx';
import * as Api from "api/api";

class QrCode {
    list = [];


    async fetchList() {
        try {
            const {list} = await Api.qrCodeList();

            this.list = list;
        } catch (error) {
        }
    }


    constructor() {
        makeAutoObservable(this)
    }
}

const qrCode = new QrCode();
export default qrCode;
