import {combineReducers} from 'redux';

import type from './action-type';
import MerchantType from 'enum/MerchantType';

const reducer = {};

reducer.list = (state = [], action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_LIST:
            return payload;
        case type.INIT:
            return [];
        default:
            return state;
    }
};

reducer.keyword = (state = '', action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_KEYWORD:
            return payload;
        case type.INIT:
            return '';
        default:
            return state;
    }
};

export const page = {
    size: 24,
    num: 1
};
reducer.page = (state = page, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_PAGE_SIZE:
            return {...state, size: payload};
        case type.SET_PAGE_NUM:
            return {...state, num: payload};
        case type.INIT:
            return page;
        default:
            return state;
    }
};

reducer.totalNum = (state = 0, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_TOTAL_NUM:
            return payload;
        case type.INIT:
            return 0;
        default:
            return state;
    }
};

const merchantType = MerchantType.All.value;
reducer.type = (state = merchantType, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_TYPE:
            return payload;
        case type.INIT:
            return merchantType;
        default:
            return state;
    }
};

reducer.descSort = (state = null, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.INIT:
            return null;
        case type.SET_DESC_SORT:
            return payload;
        default:
            return state;
    }
};

reducer.servSort = (state = null, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.INIT:
            return null;
        case type.SET_SERV_SORT:
            return payload;
        default:
            return state;
    }
};

reducer.lgstSort = (state = null, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.INIT:
            return null;
        case type.SET_LGST_SORT:
            return payload;
        default:
            return state;
    }
};

export default combineReducers({...reducer});
