import {makeAutoObservable} from 'mobx';

class Login {
    username = '';
    password = '';
    show = false;

    constructor() {
        makeAutoObservable(this)
    }
}

const login = new Login();

export default login;
