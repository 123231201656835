import styled from 'styled-components';

export const Loading = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;

  > .img-loading {
    width: 60px;
    display: block;
  }
`;