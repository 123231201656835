import {makeAutoObservable} from "mobx";

class OrderPayStore {
    id = 0;
    sn = '';
    price = 0;
    status = undefined;
    createTime = 0;
    delivery = undefined;
    orderAddress = undefined;
    orderProductList = [];
    payTypeList = [];
    currentPayTypeId = 0;
    showAddAddress = false;
    showModifyAddress = false;
    addressObj = {
        list: [],
        isOpen: false,
        currentAddress: undefined
    }
    qrCodeUrl = '';
    showQrCode = false;


    constructor() {
        makeAutoObservable(this)
    }
}

export default new OrderPayStore();