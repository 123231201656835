import {
  http,
  Header,
  ContentType,
  qs,
  GetFormData,
  StatusCode,
  Global,
  Method
} from './api-util';

export const newsCategoryList = async ({ name }) => {
  const { data } = await http({
    method: Method.POST,
    url: Global.BASE_URL + '/news/category/list',
    data: {
      name
    }
  });

  const list = data.msg.objectList.map(item => {
    const { id, name, netEase: isUrl } = item;

    return { id, name, isUrl };
  });

  const { totalNum } = data.msg;

  return {
    list,
    totalNum
  };
};
