import {makeAutoObservable} from 'mobx';

class Menu {
    is1200 = true;


    constructor() {
        makeAutoObservable(this)
    }
}

const menu = new Menu();

export default menu;
