import {
  http,
  Header,
  ContentType,
  qs,
  GetFormData,
  StatusCode,
  Global,
  Method
} from './api-util';

/**
 * 获取足迹列表
 * @param {*} param0
 */
export const trackMap = async ({ pageSize, pageNum }) => {
  const { data } = await http({
    method: Method.POST,
    url: Global.BASE_URL + '/footprint/list',
    data: {
      page: pageNum,
      size: pageSize
    }
  });

  const { map, totalNum } = data;

  return {
    map,
    totalNum
  };
};

/**
 * 删除足迹
 * @param {*} param0
 */
export const deleteTrack = async ({ id }) => {
  await http({
    method: Method.DELETE,
    url: Global.BASE_URL + '/footprint/del',
    params: {
      id
    }
  });
};

/**
 * 删除某天的足迹
 * @param {*} param0
 */
export const deleteDayTrack = async ({ date }) => {
  await http({
    method: Method.DELETE,
    url: Global.BASE_URL + '/footprint/del/someday',
    params: {
      day: date
    }
  });
};
