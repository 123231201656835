import {
    http,
    Header,
    ContentType,
    qs,
    GetFormData,
    StatusCode,
    Global,
    Method
} from './api-util';

export async function advImgList({type}) {
    return http({
        method: Method.GET,
        baseURL: Global.BASE_URL + '/api/adv_img',
        params: {
            type
        }
    }).then(res => res.data)
}