import {
    http,
    Header,
    ContentType,
    qs,
    GetFormData,
    StatusCode,
    Global,
    Method
} from './api-util';

export const addressList = () => {
    return http({
        method: Method.GET,
        url: Global.BASE_URL + '/address/list/all'
    }).then(res => res.data)
        .then(data => data.msg);
}

export const addressInfo = ({id}) => {
    return http({
        method: Method.GET,
        url: Global.BASE_URL + '/address/detail',
        params: {
            id
        }
    }).then(res => res.data)
        .then(data => data.msg);
}

export const addAddress = ({name, telNumber, province, city, district, detailInfo, isDefault}) => {
    return http({
        method: Method.POST,
        url: Global.BASE_URL + '/address/save',
        data: {
            name, telNumber, province, city, district, detailInfo, isDefault
        }
    })
}

export const modifyAddress = ({id, name, telNumber, province, city, district, detailInfo, isDefault}) => {
    return http({
        method: Method.PUT,
        url: Global.BASE_URL + '/address/update',
        data: {
            id, name, telNumber, province, city, district, detailInfo, isDefault
        }
    })
}

export const removeAddress = (id) => {
    return http({
        method: Method.DELETE,
        url: Global.BASE_URL + '/address/del',
        params: {
            id
        }
    })
}