import {
    http,
    Header,
    ContentType,
    qs,
    GetFormData,
    StatusCode,
    Global,
    Method
} from './api-util';

export const receiveDelivery = ({id}) => {
    return http({
        method: Method.PUT,
        url: Global.BASE_URL + `/delivery/receive/${id}`
    })
}