import {combineReducers} from "redux";
import type from "./action-type";

const reducer = {};

reducer.categoryList = (state = [], action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_CATEGORY_LIST:
            return payload;
        case type.INIT:
            return [];
        default:
            return state;
    }
};

reducer.currentCategoryIndex = (state = 0, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_CURRENT_CATEGORY_INDEX:
            return payload;
        case type.INIT:
            return 0;
        default:
            return state;
    }
};

reducer.list = (state = [], action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_LIST:
            return payload;
        case type.INIT:
            return [];
        default:
            return state;
    }
};

reducer.totalNum = (state = 0, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_TOTAL_NUM:
            return payload;
        case type.INIT:
            return 0;
        default:
            return state;
    }
};

export const page = {
    //   size: 125,
    size: 30,
    num: 1
};
reducer.page = (state = page, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_PAGE_SIZE:
            return {...state, size: payload};
        case type.SET_PAGE_NUM:
            return {...state, num: payload};
        case type.INIT:
            return page;
        default:
            return state;
    }
};

reducer.keyword = (state = "", action = {}) => {
    const {payload} = action;
    switch (action.type) {
        case type.SET_KEYWORD:
            return payload;
        case type.INIT:
            return "";
        default:
            return state;
    }
};

reducer.recommendList = (state = [], action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_RECOMMEND_LIST:
            return payload;
        case type.INIT:
            return [];
        default:
            return state;
    }
};

reducer.info = (state = null, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_INFO:
            return payload;
        case type.INIT:
            return null;
        default:
            return state;
    }
};

reducer.menuType = (state = null, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_MENU_TYPE:
            return payload;
        default:
            return state;
    }
};

reducer.categoryId = (state = null, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_CATEGORY_ID:
            return payload;
        default:
            return state;
    }
};

reducer.loadingList = (state = false, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_LOADING_LIST:
            return payload;
        default:
            return state;
    }
}

reducer.loadingRecommend = (state = false, action = {}) => {
    const {payload} = action;

    switch (action.type) {
        case type.SET_LOADING_RECOMMEND:
            return payload;
        default:
            return state;
    }
}

export default combineReducers({...reducer});
