import * as ActionType from 'utils/ActionType';

const type = {
  INIT: 'INIT',
  SET_LIST: 'SET_LIST',
  SET_SHOP_PAGE_SIZE: 'SET_SHOP_PAGE_SIZE',
  SET_SHOP_PAGE_NUM: 'SET_SHOP_PAGE_NUM',
  SET_GOODS_PAGE_SIZE: 'SET_GOODS_PAGE_SIZE',
  SET_GOODS_PAGE_NUM: 'SET_GOODS_PAGE_NUM'
};

ActionType.handleType({ type, namespace: 'user/shop-collect/' });

export default type;
