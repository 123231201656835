import {
    http,
    Header,
    ContentType,
    qs,
    GetFormData,
    StatusCode,
    Global,
    Method
} from './api-util';


export const pay = ({price, orderSn, payTypeId}) => {
    return http({
        url: Global.BASE_URL + '/pay',
        method: Method.POST,
        params: {
            price,
            orderSn,
            payTypeId
        }
    }).then(res => res.data);
}

export const paySocket = ({orderId}) => {
    return `${Global.BASE_SOCKET}/api/socket/pay/${orderId}`;
};