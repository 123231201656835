import * as ActionType from "utils/ActionType";

const type = {
    INIT: "INIT",
    SET_CATEGORY_LIST: "SET_CATEGORY_LIST",
    SET_CURRENT_CATEGORY_INDEX: "SET_CURRENT_CATEGORY_INDEX",
    SET_LIST: "SET_LIST",
    SET_TOTAL_NUM: "SET_TOTAL_NUM",
    SET_PAGE_SIZE: "SET_PAGE_SIZE",
    SET_PAGE_NUM: "SET_PAGE_NUM",
    SET_KEYWORD: "SET_KEYWORD",
    SET_RECOMMEND_LIST: "SET_RECOMMEND_LIST",
    SET_INFO: "SET_INFO",
    SET_MENU_TYPE: "SET_MENU_TYPE",
    SET_CATEGORY_ID: "SET_CATEGORY_ID",
    SET_LOADING_LIST: 'SET_LOADING_LIST',
    SET_LOADING_RECOMMEND: 'SET_LOADING_RECOMMEND'
};

ActionType.handleType({type, namespace: "news/"});

export default type;
