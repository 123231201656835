import { combineReducers } from 'redux';
import type from './action-type';

const reducer = {};

export const Input = {
  id: 'id',
  username: 'username',
  nickname: 'nickname',
  avatar: 'avatar',
  avatarUrl: 'avatarUrl',
  gender: 'gender',
  birthday: 'birthday'
};

const input = {
  [Input.id]: 0,
  [Input.username]: '',
  [Input.nickname]: '',
  [Input.avatar]: undefined,
  [Input.avatarUrl]: '',
  [Input.gender]: '',
  [Input.birthday]: ''
};

reducer.input = (state = input, action = {}) => {
  const { payload } = action;

  switch (action.type) {
    case type.SET_INPUT:
      return { ...state, ...payload };
    case type.INIT:
      return input;
    default:
      return state;
  }
};

export default combineReducers({ ...reducer });
