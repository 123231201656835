import cookie from 'react-cookies';

const path = '/'

const maxAge = 60 * 60 * 2

export const save = (k, v) => {
    cookie.save(k, v, {
        path,
        maxAge
    });
}

export const load = (k) => {
    return cookie.load(k)
}

export const remove = (k) => {
    cookie.remove(k, {
        path
    });
}