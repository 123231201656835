import * as ActionType from 'utils/ActionType';

const type = {
    INIT: 'INIT',
    SET_KEYWORD: 'SET_KEYWORD',
    SET_GOODS_TYPE: 'SET_GOODS_TYPE',
    SET_GOODS_LIST: 'SET_GOODS_LIST',
    SET_TOTAL_NUM: 'SET_TOTAL_NUM',
    SET_CONDITION: 'SET_CONDITION',
    SET_FROM_PRICE: 'SET_FROM_PRICE',
    SET_TO_PRICE: 'SET_TO_PRICE',
    SET_CATEGORY_ID: 'SET_CATEGORY_ID',
    SET_IS_NEW: 'SET_IS_NEW',
    SET_IS_HOT: 'SET_IS_HOT',
    SET_PAGE_SIZE: 'SET_PAGE_SIZE',
    SET_PAGE_NUM: 'SET_PAGE_NUM',
    SET_CATEGORY_LIST: 'SET_CATEGORY_LIST',
    SET_SELECTED_CATEGORY_LIST: 'SET_SELECTED_CATEGORY_LIST',
    PUSH_SELECTED_CATEGORY_LIST: 'PUSH_SELECTED_CATEGORY_LIST',
    TRUNCATE_SELECTED_CATEGORY_LIST: 'TRUNCATE_SELECTED_CATEGORY_LIST',
    SET_RECOMMEND_KEYWORD_LIST: 'SET_RECOMMEND_KEYWORD_LIST',
    SET_RECOMMEND_LIST: 'SET_RECOMMEND_LIST',
    SET_GUESS_LIST: 'SET_GUESS_LIST',
};

ActionType.handleType({type, namespace: 'search/'});

export default type;
