import {
    http,
    Header,
    ContentType,
    qs,
    GetFormData,
    StatusCode,
    Global,
    Method
} from './api-util';

export const payTypeList = ({isOpen}) => {
    return http({
        method: Method.GET,
        url: Global.BASE_URL + '/api/pay-type/list',
        params: {
            isOpen
        }
    }).then(res => res.data)
}

export const isOpenPayTypeList = () => {
    return http({
        method: Method.GET,
        url: Global.BASE_URL + '/api/pay-type/list',
        params: {
            isOpen: true
        }
    }).then(res => res.data)
}