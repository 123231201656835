export class License {
  categoryName = '';
  picture = undefined;
  endDate = '';
  isInfinite = false;

  constructor(categoryName) {
    this.categoryName = categoryName;
  }
}
