import {makeAutoObservable} from "mobx";

class OrderListStore {
    list = [];
    pageSize = 10;
    pageNum = 1;
    totalNum = 0;
    currentStatus = undefined;


    constructor() {
        makeAutoObservable(this)
    }
}

export default new OrderListStore();